<template>
  <div id="sending">

    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>

    <div class="hidden-xs-only">
      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">
          <div style="width: 100%; max-width: 1284px; margin: 0 auto">
            <div class="content">
              <div class="title">お問い合わせ</div>
              <div class="detail">
                <div class="text normal">お問い合わせは、お電話・お問い合わせフォームより承っております。</div>
                <div class="text normal">お気軽にお問い合わせください。</div>
                <div class="text normal">お電話でのお問い合わせ<span class="tel">03-6260-6008</span></div>
                <div class="text small">※ 受付時間　月・火・木・金・土　10:00 - 13:30 / 14:30-19:00</div>
                <div class="text small">※ 祝日を除く</div>

                <div class="form">
                  <div class="title">
                    <span class="ja">お問い合わせフォーム</span>
                    <span class="en">Contact Form</span>
                  </div>
                  <div class="separator"></div>
                  <el-form label-position="left"
                          :model="formData"
                          :rules="rules"
                          ref="formData"
                          size="mini"
                          label-width="150px">
                    <el-form-item label="お名前" prop="name">
                      <el-input size="mini" v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="ふりがな" prop="nameKana">
                      <el-input size="mini" v-model="formData.nameKana"></el-input>
                    </el-form-item>
                    <el-form-item label="電話番号" prop="tel">
                      <el-input size="mini" v-model="formData.tel"></el-input>
                    </el-form-item>
                    <el-form-item label="メールアドレス" prop="email">
                      <el-input size="mini" v-model="formData.email"></el-input>
                    </el-form-item>
                    <el-form-item label="郵便番号" prop="postcode">
                      <el-input size="mini" v-model="formData.postcode"></el-input>
                    </el-form-item>
                    <el-form-item label="住所" prop="address">
                      <el-input size="mini" v-model="formData.address"></el-input>
                    </el-form-item>
                    <el-form-item label="お問い合わせ内容" prop="content">
                      <el-input type="textarea" :rows="4" size="mini" v-model="formData.content"></el-input>
                    </el-form-item>

                    <div class="agreement">
                      <el-checkbox v-model="checked"></el-checkbox>
                      <el-link id="agreementLink" :underline="false" @click="dialogVisible = true">個人情報の取り扱いについて、同意します。</el-link>
                    </div>

                    <div class="submit">
                      <el-button @click="onSubmit('formData')" size="mini">送信する</el-button>
                    </div>
                  </el-form>

                </div>
              </div>

            </div>
          </div>

        </el-col>
      </el-row>

      <el-dialog :visible.sync="dialogVisible"
                 :show-close="false"
                 title="個人情報の取り扱いについて"
                 width="62.5%">
        <div class="dialog">
          <div class="text">個人情報の利用目的</div>
          <div class="text">ご入力いただいた個人情報は、お問い合わせ対応のみに利用します。</div>
          <div class="text">個人情報の第三者提供について</div>
          <div class="text">こ本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。</div>
          <div class="text">個人情報の取り扱い委託について</div>
          <div class="text">個人情報の取り扱いの一部を外部に委託する場合があります。個人情報を委託する場合は、個人情報保護水準を十分に満たしている者を選定し、適切な監督を行います。また、選定した委託先と個人情報保護に関する契約を取り交わします。</div>
          <div class="text">聞示対象個人情報の聞示等</div>
          <div class="text">こ本人からの求めにより、ご自身の個人情報の聞示、訂正、削除、消去または利用の停止などに応じます。ご希望される場合には、下記の窓口までお問い合わせください。</div>
          <div class="text">お問い合わせ窓口</div>
          <div class="text">個人情報の取り扱いに関するお問い合わせは、下記の窓口までお願いいたします。</div>
          <div class="text">患者さまサポート室　電話番号　03-6240-1393</div>
          <div class="text">受付時間　10：00～18：00（土・日・祝日を除く）</div>

          <div class="agree">
            <el-button @click="onAgree" size="mini">同意します</el-button>
          </div>
        </div>
      </el-dialog>

      <PageFooter/>
    </div>

    <div class="hidden-sm-and-up">

      <div class="m-title">お問い合わせ</div>
      <div class="m-detail">
        <div>
          <div class="text normal">お問い合わせは、お電話・お問い合わせフォームより承っております。</div>
          <div class="text normal">お気軽にお問い合わせください。</div>
        </div>

        <div>
          <div class="text normal">お電話でのお問い合わせ</div>
          <div class="tel">03-6260-6008</div>
          <div class="text small">
            <div>※ 受付時間　月・火・水・木・金・土</div>
            <div>10:00 - 13:30 / 14:30-19:00</div>
          </div>
          <div class="text small">※祝日を除く</div>
        </div>

        <div class="m-form">
          <div class="m-h2">
            <div class="ja">お問い合わせフォーム</div>
            <div class="en">Contact Form</div>
          </div>
          <div class="separator"></div>
          <el-form label-position="top"
                   :model="mFormData"
                   :rules="mRules"
                   ref="mFormData"
                   size="mini"
                   label-width="150px">

            <el-form-item label="お名前" prop="name">
              <el-input size="mini" v-model="mFormData.name"></el-input>
            </el-form-item>
            <el-form-item label="ふりがな" prop="nameKana">
              <el-input size="mini" v-model="mFormData.nameKana"></el-input>
            </el-form-item>
            <el-form-item label="電話番号" prop="tel">
              <el-input size="mini" v-model="mFormData.tel"></el-input>
            </el-form-item>
            <el-form-item label="メールアドレス" prop="email">
              <el-input size="mini" v-model="mFormData.email"></el-input>
            </el-form-item>
            <el-form-item label="郵便番号" prop="postcode">
              <el-input size="mini" v-model="mFormData.postcode"></el-input>
            </el-form-item>
            <el-form-item label="住所" prop="address">
              <el-input size="mini" v-model="mFormData.address"></el-input>
            </el-form-item>
            <el-form-item label="お問い合わせ内容" prop="content">
              <el-input type="textarea" :rows="4" size="mini" v-model="mFormData.content"></el-input>
            </el-form-item>

            <div class="m-agreement">
              <el-checkbox v-model="mChecked"></el-checkbox>
              <el-link id="m-agreementLink" :underline="false" @click="mDialogVisible = true">個人情報の取り扱いについて、同意します。</el-link>
            </div>

            <div class="m-submit">
              <el-button @click="mOnSubmit('mFormData')" size="mini" round>送信する</el-button>
            </div>

          </el-form>
        </div>
      </div>

      <el-dialog :visible.sync="mDialogVisible"
                 :show-close="true"
                 center
                 title="個人情報の取り扱いについて"
                 width="90%">
        <div class="m-dialog">
          <div class="text">個人情報の利用目的</div>
          <div class="text">ご入力いただいた個人情報は、お問い合わせ対応のみに利用します。</div>
          <div class="text">個人情報の第三者提供について</div>
          <div class="text">こ本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。</div>
          <div class="text">個人情報の取り扱い委託について</div>
          <div class="text">個人情報の取り扱いの一部を外部に委託する場合があります。個人情報を委託する場合は、個人情報保護水準を十分に満たしている者を選定し、適切な監督を行います。また、選定した委託先と個人情報保護に関する契約を取り交わします。</div>
          <div class="text">聞示対象個人情報の聞示等</div>
          <div class="text">こ本人からの求めにより、ご自身の個人情報の聞示、訂正、削除、消去または利用の停止などに応じます。ご希望される場合には、下記の窓口までお問い合わせください。</div>
          <div class="text">お問い合わせ窓口</div>
          <div class="text">個人情報の取り扱いに関するお問い合わせは、下記の窓口までお願いいたします。</div>
          <div class="text">患者さまサポート室　電話番号　03-6240-1393</div>
          <div class="text">受付時間　10：00～18：00（土・日・祝日を除く）</div>

          <div class="m-agree">
            <el-button @click="mOnAgree" size="mini" round>同意します</el-button>
          </div>
        </div>
      </el-dialog>

      <m-footer/>
    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import MTop from "../components/m/MTop";
import MFooter from "../components/m/MFooter";
import {Dialog, Notify} from "vant";
export default {
  name: "Sending",
  components: {MFooter, MTop, PageFooter, PageHeader},
  data() {
    return {
      formData: {
        name: '',
        nameKana: '',
        tel: '',
        email: '',
        postcode: '',
        address: '',
        content: ''
      },
      mFormData: {
        name: '',
        nameKana: '',
        tel: '',
        email: '',
        postcode: '',
        address: '',
        content: ''
      },
      rules: {
        name: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        nameKana: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        email: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        content: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ]
      },
      mRules: {
        name: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        nameKana: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        email: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ],
        content: [
          {required: true, message: '※入力してくださ。', trigger: 'change'}
        ]
      },
      checked: false,
      mChecked: false,
      dialogVisible: false,
      mDialogVisible: false
    }
  },
  methods: {
    onSubmit(formData) {
      /*送信时，需要检查checked的值。。*/
      if (!this.checked) {
        let a = document.getElementById('agreementLink')
        a.style.color = 'red'
        return
      }

      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.$confirm('送信しましたか？', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then(() => {
            
            // console.log(`name:${formData.name}`)
            // console.log(`nameKana:${formData.nameKana}`)
            // console.log(`tel:${formData.tel}`)
            // console.log(`email:${formData.email}`)
            // console.log(`postcode:${formData.postcode}`)
            // console.log(`address:${formData.address}`)
            // console.log(`content:${formData.content}`)


            axios.post(`${this.GLOBAL.host}/t/contact/insert`, this.formData).then(() => {
              this.$message({
                type: 'success',
                message: '送信しました'
              })
            })
          }).catch(() => {
            this.$message({
              type: "info",
              message: 'キャンセルしました'
            })
          })
        } else {
          this.$message({
            type: 'error',
            message: '入力してくださ。'
          })
        }
      })

    },
    mOnSubmit(mFormData) {
      /*送信时，需要检查checked的值。。*/
      if (!this.mChecked) {
        let a = document.getElementById('m-agreementLink')
        a.style.color = 'red'
        return
      }

      this.$refs[mFormData].validate((valid) => {
        if (valid) {
          Dialog.confirm({
            message: '送信しましたか？',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then(() => {
            console.log('yes')
            axios.post(`${this.GLOBAL.host}/t/contact/insert`, this.formData).then(() => {
              Notify({
                message: '送信しました',
                type: 'success'
              })
            })
          }).catch(() => {
            Notify({
              message: 'キャンセルしました',
              type: 'warning'
            })
          })
        } else {
          Notify({
            message: '入力してくださ。',
            type: 'danger'
          })
        }
      })
    },
    onAgree() {
      this.dialogVisible = false
      this.checked = true
    },
    mOnAgree() {
      this.mDialogVisible = false
      this.mChecked = true
    }
  },
  watch: {
    checked() {
      if (this.checked) {
        let a = document.getElementById('agreementLink')
        a.style.color = '#333'
      }
    },
    mChecked() {
      if (this.mChecked) {
        let a = document.getElementById('m-agreementLink')
        a.style.color = '#333'
      }
    }
  }
}
</script>

<style scoped>
/* PC */
.content {
  padding: 30px;
}
.title {
  color: #535353;
  font-size: 24px;
}
.detail {
  margin-top: 30px;
}
.detail .text{
  margin-top: 10px;
}
.detail .text:nth-child(3) {
  margin-top: 40px;
}
.detail .text .tel {
  margin-left: 20px;
  font-size: 22px;
  color: #62b6e2;
}
.detail .small {
  font-size: 14px;
}

.form {
  margin-top: 50px;
}
.form .title {
  font-size:20px;
}
.title .en {
  margin-left: 20px;
  font-size: 16px;
  color: #aaa;
  letter-spacing: 3px;
}
.separator {
  margin: 20px 0;
  border-top: 1px dotted #CCCCCC;
}
.form /deep/ .el-form .el-form-item div {
  width: 400px;
}
.agreement {
  margin: 20px 0 0 150px;
}
.agreement a {
  margin-left: 10px;
}

/*送信按钮*/
.submit {
  margin: 40px 0 0 150px;
}
.submit /deep/ .el-button {
  border-radius: 5px;
  background-color: #62b6e2;
  color: #fff;
  font-size: 14px;
  padding: 10px 40px;
}

/*弹窗配置*/
/deep/ .el-dialog {
  padding: 30px 30px 10px 30px;
  border-radius: 10px;
}
/deep/ .el-dialog .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dialog .text {
  line-height: 20px;
  font-size: 14px;
}
.dialog .text:nth-child(3), .dialog .text:nth-child(5), .dialog .text:nth-child(7), .dialog .text:nth-child(9) {
  margin-top: 20px;
}

.dialog .agree {
  text-align: right;
  margin: 30px auto;
}
.dialog .agree /deep/ .el-button {
  width: 130px;
  height: 30px;

  background-color: #62b6e2;
  color: #FFFFFF;
  border-radius: 5px;
}

/* SP */
.m-title {
  color: #535353;
  font-size: 20px;
  text-align: center;
  padding: 30px 20px 20px;
}
.m-detail {
  font-size: 14px;
  padding: 0 20px;
}
.m-info {
  margin-top: 20px;
}
.m-info .tel {
  color: #62b6e2;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}
.m-info .small {
  font-size: 12px;
}
.m-form .m-h2 {
  font-size: 20px;
  margin-top: 20px;
}
.m-form .m-h2 .en {
  margin-top: 6px;
  font-size: 16px;
  color: #9e9e9e;
  letter-spacing: 3px;
}
.m-form /deep/ .el-form>div {
  margin-bottom: 10px;
}
.m-form /deep/ .el-form>div>label {
  padding-bottom: 2px;
}
.m-form /deep/ .el-form .m-agreement > a {
  margin-left: 10px;
}
.m-form /deep/ .el-form .m-submit,
.m-form /deep/ .el-form .m-agreement {
  text-align: center;
}
.m-form .m-submit /deep/ .el-button {
  background-color: #62b6e2;
  border-radius: 5px;
  border: none;
  box-shadow: 0 1px 2px #ccc;
  color: #fff;
  font-size: 14px;
  padding: 10px 40px;
  margin: 20px 0;
}
.hidden-md-and-up /deep/ .el-dialog {
  padding: 30px 0 10px 0;
  border-radius: 10px;
}
.hidden-md-and-up /deep/ .m-dialog .text {
  line-height: 20px;
  font-size: 14px;
}
.hidden-md-and-up /deep/ .m-dialog .text:nth-child(3),
.hidden-md-and-up /deep/ .m-dialog .text:nth-child(5),
.hidden-md-and-up /deep/ .m-dialog .text:nth-child(7),
.hidden-md-and-up /deep/ .m-dialog .text:nth-child(9) {
  margin-top: 20px;
}

.hidden-md-and-up /deep/ .m-dialog .m-agree {
  text-align: center;
  margin: 2rem 0 1rem;
}
.hidden-md-and-up /deep/ .m-dialog .m-agree .el-button {
  font-size: 18px;
  background-color: #62b6e2;
  color: #fff;
}
</style>
